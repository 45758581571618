@import "@Static/css/common";

// theme colours
$color1: getColorValue(1, (29, 57, 203));
$color2: getColorValue(2, (255, 255, 255));

// extra colours
$color3: getColorValue(2, (255, 255, 255), 0.3);

$cardBgColor: rgb(var(--template-button-background-host-theme, 0, 0, 0));
$cardTextColor: rgb(var(--template-button-text-host-theme, 255, 255, 255));

// common classes
.exlyBrandingFontSize {
    font-size: 0.8rem;
}

// component specific classes
.videoContainer {
    width: 100%;
    iframe,
    video {
        border: 4px solid $color3 !important;
        border-radius: 3rem;
    }
}

.getInTouchButton {
    background-color: $cardBgColor;
    color: $cardTextColor;
    width: 100%;
    padding: 10px 0;
}

.divider {
    margin: 30px 0;
    width: 100%;
}

.exlyBrandingContainer {
    @extend .exlyBrandingFontSize;
}

.exlyLogo {
    color: inherit;
    @extend .exlyBrandingFontSize;
}
