@import "@Static/css/common";

// theme colours
$color2: getColorValue(2, (255, 255, 255));

.profileImage {
    border: 4px solid $color2 !important;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;

    &.preview {
        width: 125px;
        height: 125px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
