@import "@Static/css/common";

// theme colours - keep all colors(even commented ones)
// for reference for subcomponents
$color1: getColorValue(1, (29, 57, 203));
$color2: getColorValue(2, (255, 255, 255));
$textColor: getColorValue(3, (255, 255, 255));
// $color3: getColorValue(3, (4, 3, 4));

.root {
    min-height: 100dvh;
    overflow-y: auto;
    @extend .themedScrollbar;
    width: 100%;
    background-color: $color1;
    display: flex;
    flex-direction: column;
    color: $textColor;
    font-weight: 500;
    font-size: 0.9rem;
}

.background {
    flex-grow: 1;
}

.container {
    max-width: 600px;
    margin: auto;
    margin-top: 4.5rem;
    padding: 0 1rem;
}

.sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
}

.sectionAnchor {
    position: relative;
    top: -60px;
}

.sectionTitle {
    margin: 16px 0;
    font-weight: bolder;
    font-size: 1.2rem;
}
