@import "@Static/css/common";

$bgColor: rgb(var(--template-button-background-host-theme, 0, 0, 0));
$textColor: rgb(var(--template-button-text-host-theme, 255, 255, 255));

.container {
    background-color: $bgColor;
    color: $textColor;
    padding: 1rem;
    width: 100%;
    margin-bottom: 1rem;

    cursor: pointer;
    transition: 0.2s ease all;
    position: relative;

    &:hover {
        transform: translateY(-4px) scale(1.01);
    }
}

.title {
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.shortDescription {
    font-size: 0.7rem;
}
